var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[(_vm.showLable)?_c('p',[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('sup',{staticClass:"display-flex required"},[_vm._v("*")]):_vm._e(),(_vm.isTooltip)?_c('b-tooltip',{attrs:{"type":"is-info","label":_vm.tooltipMsg,"multilined":""}},[_c('b-icon',{attrs:{"icon":"information-variant-circle","size":"is-small"}})],1):_vm._e()],1):_vm._e(),_c('b-input',{staticClass:"form-control",class:_vm.customBorder ? 'darkBorder' : '',attrs:{"autocomplete":"new-password","type":_vm.type,"disabled":_vm.disabled,"readonly":_vm.readonly,"password-reveal":_vm.passwordReveal,"icon-pack":"none","placeholder":_vm.placeholder},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnterFun.apply(null, arguments)},"input":_vm.handleInput,"focus":_vm.handleFocus},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),(_vm.errorToDisplay)?_c('div',{staticClass:"required-error"},[_vm._v(" "+_vm._s(_vm.errorToDisplay)+" ")]):_vm._e(),(_vm.min && !_vm.$v.name.minLength && _vm.$v.name.numeric)?_c('span',{staticClass:"required-error"},[_vm._v(_vm._s(_vm.label)+" must be greater than "+_vm._s(_vm.min)+" "+_vm._s(_vm.isNumeric ? 'digits' : 'characters')+".")]):_vm._e(),(_vm.max && !_vm.$v.name.maxLength && _vm.$v.name.numeric)?_c('span',{staticClass:"required-error"},[_vm._v(_vm._s(_vm.label)+" must be less than "+_vm._s(_vm.max)+" "+_vm._s(_vm.isNumeric ? 'digits' : 'characters')+".")]):_vm._e(),(!_vm.$v.name.email && _vm.$v.name.minLength && _vm.$v.name.maxLength)?_c('span',{staticClass:"required-error"},[_vm._v("Email is invalid.")]):_vm._e(),(
      !_vm.$v.name.alphaNum && _vm.$v.name.minLength && _vm.$v.name.maxLength && _vm.alphaNum
    )?_c('span',{staticClass:"required-error"},[_vm._v("Only alphanumeric characters allowed.")]):_vm._e(),(
      !_vm.$v.name.alphaNumAndSpecialChar &&
      _vm.$v.name.minLength &&
      _vm.$v.name.maxLength &&
      _vm.alphaNumAndSpecialChar &&
      _vm.$v.name.required
    )?_c('span',{staticClass:"required-error"},[_vm._v("Only alphanumeric ,space ( ) - and & characters are allowed.")]):_vm._e(),(
      !_vm.$v.name.alphaNumSpaceAndDash &&
      _vm.$v.name.asciiEnglishCharactersOnly &&
      _vm.$v.name.minLength &&
      _vm.$v.name.maxLength &&
      _vm.alphaNumSpaceAndDash &&
      _vm.$v.name.required
    )?_c('span',{staticClass:"required-error"},[_vm._v("Only alphanumeric ,space and - characters are allowed.")]):_vm._e(),(
      !_vm.$v.name.alphaNumericAndHyphen &&
      _vm.$v.name.minLength &&
      _vm.$v.name.maxLength &&
      _vm.alphaNumericAndHyphen &&
      _vm.$v.name.required
    )?_c('span',{staticClass:"required-error"},[_vm._v("Only alphanumeric and - are allowed.")]):_vm._e(),(!_vm.$v.name.url && _vm.$v.name.minLength && _vm.$v.name.maxLength)?_c('span',{staticClass:"required-error"},[_vm._v("Enter correct url.")]):_vm._e(),(
      !_vm.$v.name.internationalPhone && _vm.$v.name.minLength && _vm.$v.name.maxLength
    )?_c('span',{staticClass:"required-error"},[_vm._v("Invalid phone number.")]):_vm._e(),(!_vm.$v.name.ISIN && _vm.$v.name.minLength && _vm.$v.name.maxLength)?_c('span',{staticClass:"required-error"},[_vm._v("Invalid ISIN number.")]):_vm._e(),(!_vm.$v.name.duns && _vm.$v.name.minLength && _vm.$v.name.maxLength)?_c('span',{staticClass:"required-error"},[_vm._v("Invalid DUNS number.")]):_vm._e(),(!_vm.$v.name.numeric)?_c('span',{staticClass:"required-error"},[_vm._v("Enter valid zip code")]):_vm._e(),(_vm.isPositive && _vm.name < 0)?_c('span',{staticClass:"required-error"},[_vm._v("Enter a positive number.")]):_vm._e(),(_vm.isNonZero && parseInt(_vm.name) === 0)?_c('span',{staticClass:"required-error"},[_vm._v("Enter non zero value.")]):_vm._e(),(
      !_vm.$v.name.alphaNumSpace &&
      _vm.$v.name.minLength &&
      _vm.$v.name.maxLength &&
      _vm.alphaNumSpace &&
      _vm.$v.name.required
    )?_c('span',{staticClass:"required-error"},[_vm._v("Only alphanumeric and space are allowed.")]):_vm._e(),(
      !_vm.$v.name.asciiEnglishCharactersOnly &&
      _vm.$v.name.minLength &&
      _vm.$v.name.maxLength
    )?_c('span',{staticClass:"required-error"},[_vm._v("Non English characters are not allowed.")]):_vm._e(),(
      !_vm.$v.name.alphaNumBracsAndHyphen &&
      _vm.$v.name.minLength &&
      _vm.$v.name.maxLength &&
      _vm.alphaNumBracsAndHyphen &&
      _vm.$v.name.required
    )?_c('span',{staticClass:"required-error"},[_vm._v("Only alphanumeric, space ( ) - and & characters are allowed.")]):_vm._e(),(
      !_vm.$v.name.newLineValidation && _vm.$v.name.minLength && _vm.$v.name.maxLength
    )?_c('span',{staticClass:"required-error"},[_vm._v("New line character is not allowed")]):_vm._e(),(
      !_vm.$v.name.hashSpecialCharValidation &&
      _vm.$v.name.pipeSpecialCharValidation &&
      _vm.$v.name.minLength &&
      _vm.$v.name.maxLength &&
      _vm.$v.name.newLineValidation
    )?_c('span',{staticClass:"required-error"},[_vm._v(" ## character is not allowed")]):_vm._e(),(
      !_vm.$v.name.pipeSpecialCharValidation &&
      _vm.$v.name.minLength &&
      _vm.$v.name.maxLength &&
      _vm.$v.name.newLineValidation
    )?_c('span',{staticClass:"required-error"},[_vm._v(" Pipe ( | ) character is not allowed")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }